var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "dropzone",
          class: { dropzone: true, dragover: _vm.dragover },
          on: {
            drop: function($event) {
              $event.preventDefault()
              return _vm.addFile($event)
            },
            dragover: function($event) {
              $event.preventDefault()
            },
            dragenter: function($event) {
              _vm.dragover = true
            },
            dragleave: function($event) {
              _vm.dragover = false
            },
            click: function($event) {
              return _vm.openDialog()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.caption) + " ")]
      ),
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.uploadProblemSecs,
            callback: function($$v) {
              _vm.uploadProblemSecs = $$v
            },
            expression: "uploadProblemSecs"
          }
        },
        [_vm._v(" " + _vm._s(_vm.uploadProblems) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }