<template>
  <div>
    <div class="dropzone" 
        v-cloak 
        v-bind:class="{ dropzone: true, dragover: dragover }"
        @drop.prevent="addFile" 
        @dragover.prevent 
        @dragenter="dragover = true" 
        @dragleave="dragover = false"
        @click="openDialog()">
      {{ caption}}
    </div>

    <b-alert v-model="uploadProblemSecs" variant="danger" dismissible>
      {{ uploadProblems }}
    </b-alert>
  </div>
</template>

<script>
  export default {
    name: 'AssetUploadComponent',
    components: {
    },
    props: {
      caption: String,
      multiple: Boolean,
    },
    data () {
      return {
        dragover: false,
        invalidFiles: [],
        uploadProblemSecs: 0,
        uploadProblems: '',
        uploading: false,
        statusText: {
          uploading: "Uploading",
          uploadComplete: "Upload ready",
          uploadError: "Upload error",
          invalidAudio: "Audio invalid",
        }
      }
    },
    methods: {
      openDialog () {
        const fileSelector = document.createElement("input")
        fileSelector.setAttribute('type', 'file')
        if (this.multiple) {
          fileSelector.setAttribute('multiple', 'multiple')
        }
        fileSelector.addEventListener('change', (e) => {
          this.addFile(e, fileSelector.files)
        })
        fileSelector.click()
      },
      addFile (e, files) {
        let invalidFiles = []

        this.dragover = false
        let droppedFiles;

        if (files) {
          droppedFiles = files
        } else {
          droppedFiles = e.dataTransfer.files
        }
        
        if (!droppedFiles) {
          return
        }

        if (this.multiple == false && droppedFiles.length > 1) {
          alert('Please upload one file')
        }

        ([...droppedFiles]).forEach(f => {
          new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = resolve
            reader.readAsArrayBuffer(f);
          }).then((event) => {
            crypto.subtle.digest('SHA-384', event.target.result).then(hashBuffer => {
              let hashArray = Buffer.from(new Uint8Array(hashBuffer));
              
              f.sha = hashArray.toString('base64')
              f.sha_hex = hashArray.toString('hex')
              f.status = ''
              f.isAudio = null;
              f.uploadPercentage = 0
              f.uploadComplete = false
              f.extension = f.name.split('.').pop().toLowerCase()
              
              if (['wav', 'jpg'].includes(f.extension)) {
                this.$emit('add', f, false)
             } else {
                invalidFiles.push(f)
                return
              }

              this.uploadFile(f);
            })
          }) 
        });

        if (invalidFiles.length > 0) {
          this.uploadProblemSecs = 5
          this.uploadProblems = 'You have uploaded invalid files ('+ invalidFiles.length +'), these have been ignored';
        }
      },
      uploadFile (file) {
        let formData = new FormData()
        formData.append('file', file)
        file.status = 'uploading'

        const onUploadProgress = (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              file.uploadPercentage = percentCompleted
              this.$emit('update', file, false)
        }

        this.$http.post('/temporary-blobs/' + file.sha_hex,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress
          }
        ).then(response => {
          file.info = response.data.Info
          file.isAudio = file.extension == 'wav'
          if ((file.isAudio) && (file.info.format != 'wav' || file.info.bitsPerSample < 16 || file.info.sampleRate < 44100 || file.info.channels != 2)) {
            file.status = 'invalidAudio'
          } else {
            file.status = 'uploadComplete'
          }
          file.uploadComplete = true
          this.$emit('update', file, true)
        })
        .catch(e => {
          console.log('Upload error', e)
          file.status = 'uploadError'
          this.$emit('update', file, false)
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  .dropzone {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border: 2px dashed $color-blue;
    border-radius: 5px;
    background-color: #282933;
    margin-bottom: 20px;

    &.dragover {
      border-style: solid;
      background-color: #414353;
    }
  }
</style>